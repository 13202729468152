* {
    margin: 0;
    padding: 0;
    font-family: "NunitoSans-Regular";
}

/* Width */
::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d6d6d6;
    background: #d6d6d6;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #565682;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #7171a8;
}

@font-face {
    font-family: "NunitoSans-Regular";
    src: url("assets/fonts/NunitoSans-Regular.ttf") format("truetype"),
    local("NunitoSans-Regular");
}

@font-face {
    font-family: "NunitoSans-SemiBold";
    src: url("assets/fonts/NunitoSans-SemiBold.ttf") format("truetype"),
    local("NunitoSans-SemiBold");
}

@font-face {
    font-family: "NunitoSans-ExtraBold";
    src: url("assets/fonts/NunitoSans-ExtraBold.ttf") format("truetype"),
    local("NunitoSans-ExtraBold");
}

@font-face {
    font-family: "NunitoSans-Bold";
    src: url("assets/fonts/NunitoSans-Bold.ttf") format("truetype"),
    local("NunitoSans-Bold");
}

@font-face {
    font-family: "LibreBaskerville-Bold";
    src: url("assets//fonts/LibreBaskerville-Bold.ttf") format("truetype"),
    local("LibreBaskerville-Bold");
}

@font-face {
    font-family: "LibreBaskerville-Regular";
    src: url("assets//fonts/LibreBaskerville-Regular.ttf") format("truetype"),
    local("LibreBaskerville-Regular");
}

.react-datepicker__input-container,
.react-datepicker-wrapper,
.date_label {
    flex: 1;
    display: flex;
}

.date-picker {
    width: 100%;
    height: 35px;
    background: #ffffff;
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
    font-size: 13px;
    font-family: "NunitoSans-SemiBold";
    color: #565682;
    padding-left: 15px;
    cursor: pointer;
}

.react-datepicker-popper {
    z-index: 100;
    left: -12px !important;
}

/* Receipt Table */
.receipt_col1 {
    padding: 0 15px;
}

.receipt_col2 {
    padding-right: 15px;
}

.receipt_col_buttons {
    display: flex;
    align-items: center;
    padding-right: 15px;
    width: 90px;
}

.receipt_col3 {
    padding-right: 15px;
}

.receipt_col4 {
    padding-right: 15px;
}

.receipt_col6 {
    padding-right: 15px;
}

.receipt_col7 {
    padding-right: 15px;
}

.receipt_col {
    padding-right: 15px;
}

/* History Table */
.history_col1 {
    padding-right: 30px;
    min-width: 146px;
}

.history_col2 {
    padding-right: 30px;
}

.history_col3 {
    max-width: 100px;
}

.side-bar::-webkit-scrollbar {
    display: none;
}

.side {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Admin Companies Table */
.admin_col1 {
    padding-left: 15px;
    width: 75px;
}

.admin_col2 {
    padding-left: 15px;
}

.admin_col4 {
    width: 300px;
    padding-right: 15px;
}
